<template>
  <PageSection
    class="MediaOutletTierManager"
    :page-tooltip="$t('pages.settings.brand.tier.tooltip')"
    :page-title="$t('components.brand_management.media_outlet_tier_management')"
  >
    <DashboardFiltersV2 class="has-background-white border-b-black">
      <template #secondary>
        <DashboardFiltersV2Item>
          <DashboardFiltersCountryPicker />
        </DashboardFiltersV2Item>

        <div class="DashboardFiltersV2-ButtonsContainer">
          <DashboardFiltersV2Item>
            <button
              class="button is-primary"
              @click="openAddMoreDialog"
            >
              {{ $t('forms.add_new') }}
            </button>
          </DashboardFiltersV2Item>
        </div>
      </template>
    </DashboardFiltersV2>

    <div class="m-t-l">
      <div class="m-b-m">
        <Heading :serif="false" size="4">
          {{ $t('general.tier') }} A
        </Heading>

        <MediaOutletsList
          :loading="isLoading.aplus"
          :outlets="brandMediaOutlets.aplus"
          tier="aplus"
          @update="fetchMediaOutletsForTier('aplus')"
          @add-new="openAddMoreDialog"
        />
      </div>

      <VSeparator dashed color="grey-dark" />

      <div>
        <Heading :serif="false" size="4">
          {{ $t('general.tier') }} B
        </Heading>

        <MediaOutletsList
          :loading="isLoading.bplus"
          :outlets="brandMediaOutlets.bplus"
          tier="bplus"
          @update="fetchMediaOutletsForTier('bplus')"
          @add-new="openAddMoreDialog"
        />
      </div>
    </div>

    <MediaOutletsTierManagerModal ref="mediaOutletTierModal" @outlets-added="fetchMediaOutletsForTier" />
  </PageSection>
</template>

<script>
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'

import Heading from '@hypefactors/shared/js/components/core/Heading.vue'
import VSeparator from '@hypefactors/shared/js/components/core/VSeparator.vue'
import DashboardFiltersCountryPicker from '@hypefactors/shared/js/components/filters/DashboardFiltersCountryPicker.vue'
import DashboardFiltersV2 from '@hypefactors/shared/js/components/filters/DashboardFiltersV2.vue'
import DashboardFiltersV2Item from '@hypefactors/shared/js/components/filters/DashboardFiltersV2Item.vue'

import PageSection from '@/components/core/PageSection.vue'
import MediaOutletsList from '@/pages/settings/brand/MediaOutletsList.vue'
import MediaOutletsTierManagerModal from '@/pages/settings/brand/MediaOutletsTierManagerModal.vue'

export default {
  components: {
    PageSection,
    Heading,
    VSeparator,
    DashboardFiltersV2,
    DashboardFiltersV2Item,
    DashboardFiltersCountryPicker,
    MediaOutletsList,
    MediaOutletsTierManagerModal
  },

  data () {
    return {
      isLoading: {
        aplus: false,
        bplus: false
      },

      brandMediaOutlets: {
        aplus: {},
        bplus: {}
      },

      cancelTokens: {
        aplus: null,
        bplus: null
      }
    }
  },

  watch: {
    activeBrandId: {
      immediate: true,
      handler: 'fetchBrandMediaOutlets'
    }
  },

  beforeDestroy () {
    this.cancelTokens.aplus && this.cancelTokens.aplus.cancel()
    this.cancelTokens.bplus && this.cancelTokens.bplus.cancel()
  },

  methods: {
    fetchBrandMediaOutlets () {
      return Promise.all([
        this.fetchMediaOutletsForTier('aplus'),
        this.fetchMediaOutletsForTier('bplus')
      ])
    },

    async fetchMediaOutletsForTier (tier) {
      this.isLoading[tier] = true

      this.cancelTokens[tier] && this.cancelTokens[tier].cancel()

      this.cancelTokens[tier] = this.$api.cancelToken()

      try {
        const response = await this.$api.fetchAllWithCursor(`/brands/${this.activeBrandId}/tiers?tier=${tier}`, {
          cancelToken: this.cancelTokens[tier].token
        })

        this.separateAndGroup(response, tier)
      } catch (error) {
        if (this.$api.isCancelToken(error)) {
          return
        }

        this.$displayRequestError(error, this.$t('errors.media_outlets_could_not_be_fetched'))
      } finally {
        this.isLoading[tier] = false
      }
    },

    separateAndGroup (data, tier) {
      const ordered = _orderBy(data, outlet => (this.$safeGet(outlet, 'country.iso_alpha2', 'x_uncategorized').toLowerCase() + outlet['name'].toLowerCase()))

      this.brandMediaOutlets[tier] = _groupBy(ordered, (outlet) => {
        return this.$safeGet(outlet, 'country.iso_alpha2', 'x_uncategorized')
      })
    },

    openAddMoreDialog ($event) {
      this.$refs.mediaOutletTierModal.openModal($event)
    }
  }
}
</script>
